<template>
  <div class="Data">
    <Header back="返回" index="首页" beforeTitle="数据权限" title="权限管理" titleOne="权限管理" />
    <div class="content" :data="mydata">
      <h1>数据权限</h1>
      <div>
        <dl>
          <dt>权限名称</dt>
          <dt>仅看自己数据</dt>
          <dt>可看自己和下属数据</dt>
          <dt>所有数据可见</dt>
        </dl>
        <dl>
          <dd>客户权限</dd>
          <el-radio-group v-model="mydata.company_rule" @change="agreeChange">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>合同权限</dd>
          <el-radio-group v-model="mydata.contract_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>项目权限</dd>
          <el-radio-group v-model="mydata.order_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>汇报权限</dd>
          <el-radio-group v-model="mydata.report_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>财务权限</dd>
          <el-radio-group v-model="mydata.finance_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>科创权限</dd>
          <el-radio-group v-model="mydata.finance_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
      </div>
      <!-- <div>
        <h2>部门助理</h2>
        <dl>
          <dt>权限名称</dt>
          <dt>仅看自己数据</dt>
          <dt>可看自己和下属数据</dt>
          <dt>所有数据可见</dt>
        </dl>
        <dl>
          <dd>客户权限</dd>
          <el-radio-group v-model="mydata.assistant.company_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>合同权限</dd>
          <el-radio-group v-model="mydata.assistant.contract_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>项目权限</dd>
          <el-radio-group v-model="mydata.assistant.order_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>汇报权限</dd>
          <el-radio-group v-model="mydata.assistant.report_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
        <dl>
          <dd>财务权限</dd>
          <el-radio-group v-model="mydata.assistant.finance_rule">
            <el-radio :label="1"></el-radio>
            <el-radio :label="2"></el-radio>
            <el-radio :label="3"></el-radio>
          </el-radio-group>
        </dl>
      </div>-->
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      radio: 3,
      mydata: {
        role_id: "",
        // assistant: {},
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  created () {
    this.axios
      .get("/api/role/view_data_rule", {
        params: {
          role_id: this.meber_id,
        },
      })
      .then((res) => {
        // if (res.data.length == 0) res.data = {};
        // if (res.data.assistant.length == 0) res.data.assistant = {};
        this.mydata = res.data;
      });
  },
  methods: {
    returnPage () {
      this.$router.go(-1);
    },
    agreeChange () {
      console.log(this.mydata.company_rule);
    },
    onSubmit () {
      this.mydata.role_id = this.meber_id;
      this.btnLoading = true
      this.axios.post("/api/role/store_data_rule", this.mydata).then((res) => {
        this.$router.push("/authority/index");
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.btnLoading = false
      }).catch(() => {
        this.btnLoading = false
      })
    },
  },
};
</script>

<style scoped="scoped">
.Data dl {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.Data dl dt {
  display: inline-block;
  width: 25%;
  color: #a6abc7;
}

.Data dl dd {
  display: inline-block;
  width: 25%;
  margin: 0 !important;
}

::v-deep .el-radio__label {
  display: none;
}

::v-deep .el-radio {
  width: 33.33%;
  margin: 0 !important;
}

::v-deep .el-radio-group {
  width: 75%;
}
</style>
